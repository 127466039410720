import { getCookie } from 'common/cookie';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from './useAuth';
import useApiWrapper from '../services/api/apiWrapper';

const PopupContext = createContext({});

export const PopupContextProvider = ({ children }) => {
  const { userInfo, refreshUserInfo } = useAuth()
  const { customAttributes } = userInfo
  const [isFeedbackModalSkipped, setIsFeedbackModalSkipped] = useState(getCookie(`skippedSurvey_${userInfo?.unicityId}`) === 'true');
  const [isFeedbackTaken, setIsFeedbackTaken] = useState(getCookie(`tookSurvey_${userInfo?.unicityId}`) === 'true');
  const flags = useFlags()
  const api = useApiWrapper();
  const [isPopupActive, setIsPopupActive] = useState(false)
  const [isPopupInCardForm, setIsPopupInCardForm] = useState(false)
  const [titleKeys] = useState({})
  const [popupData, setPopupData] = useState([])

  useEffect(() => {
    if (!flags?.showPopupModal?.length) {
      setIsPopupActive(false)
      setIsPopupInCardForm(false)
      return
    }

    const targetCountries = flags.showPopupModal[0]?.targetCountries
    const isUserInTargetCountry = targetCountries.includes(userInfo.countryCode)
    setIsPopupActive(flags.showPopupModal.length > 0 && isUserInTargetCountry)
    setIsPopupInCardForm(customAttributes?.popup?.isPopupInCardForm && isUserInTargetCountry)
    setPopupData(flags.showPopupModal)
    // eslint-disable-next-line
  }, [flags, userInfo])

  useEffect(() => {
    if (!userInfo && !userInfo?.unicityId) return
    setIsFeedbackModalSkipped(getCookie(`skippedSurvey_${userInfo?.unicityId}`) === 'true')
    setIsFeedbackTaken(getCookie(`tookSurvey_${userInfo?.unicityId}`) === 'true')
  }, [userInfo?.unicityId, userInfo])

  const handleClosePopup = async () => {
    setIsPopupInCardForm(true)
    await api.patch(`${process.env.REACT_APP_OFFICE_BE_PH_URL}/api/user/${userInfo.unicityId}/attributes`,
      {
        popup: { isPopupInCardForm: true, isUserDoneWithPopup: false }
      },
      {
        addPrefix: false,
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      })
    refreshUserInfo(userInfo.token)
  }

  return (
    <PopupContext.Provider value={{
      isFeedbackModalSkipped,
      setIsFeedbackModalSkipped,
      isFeedbackTaken,
      setIsFeedbackTaken,
      isPopupActive,
      isPopupInCardForm,
      titleKeys,
      popupData,
      handleClosePopup
    }}>
      {children}
    </PopupContext.Provider>
  )
}

export const usePopupContext = () => {
  const ctx = useContext(PopupContext);

  if (!ctx) throw new Error('Error in PopupContext');

  return ctx;
}