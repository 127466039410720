import React, { useState, createContext, useEffect } from "react";

import { useApi } from "../hooks/useApi"
import { useAuth } from "../hooks/useAuth"

const ActivityFeedContext = createContext([{}, () => { }]);

const ActivityFeedContextProvider = (props) => {
  // activity feed state
  const [activityFeedOpen, setActivityFeedOpen] = useState(false);
  const [activities, setActivities] = useState([]);
  const [newCount, setNewCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const { sendRequest, response, status } = useApi();
  const { userInfo } = useAuth();

  const resetContext = () => {
    setActivityFeedOpen(false);
    setActivities([]);
    setNewCount(0);
    setLoading(true);
  }

  useEffect(() => {
    if (!userInfo.loggedIn) {
      resetContext();
    }
  }, [userInfo])

  // get activities on mount and start intervalic API calls
  useEffect(() => {
    const abortController = new AbortController();
    let intervalId;
    // make initial call dependent on userInfo and activityFeedOpen (or mount) then make call everything 30 seconds after
    // https://stackoverflow.com/questions/57542264/how-to-setinterval-for-every-5-second-render-with-react-hook-useeffect-in-react
    if (userInfo.loggedIn) {
      sendRequest({
        method: "get",
        endpoint: `${process.env.REACT_APP_OFFICE_API}/${userInfo.unicityId}/activities`,
        addPrefix: false,
        abortController
      }).then(() => {
        intervalId = setInterval(() => {  //assign interval to a variable to clear it.
          sendRequest({
            method: "get",
            endpoint: `${process.env.REACT_APP_OFFICE_API}/${userInfo.unicityId}/activities`,
            addPrefix: false,
            abortController
          })
        }, 30000)
      }
      )
    }
    return () => {
      abortController.abort();
      clearInterval(intervalId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo])

  // when response, populate activities and unseenCount
  useEffect(() => {
    if (response && response.data?.items && userInfo.loggedIn && status !== "wait") {
      let sorted = response.data.items.sort((a, b) => -a.dateCreated.localeCompare(b.dateCreated))
      setActivities(sorted);
      setNewCount(response.data?.notSeenCount);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, status])

  return (
    <ActivityFeedContext.Provider
      value={{
        activityFeedOpen,
        setActivityFeedOpen,
        activities,
        setActivities,
        loading,
        newCount,
        setNewCount
      }}
    >
      {props.children}
    </ActivityFeedContext.Provider>
  );
};

export { ActivityFeedContext, ActivityFeedContextProvider };
