import { useEffect, useState, createContext } from "react";

import { useApi } from "./useApi";
import { useAuth } from "./useAuth";

const MemberContext = createContext([{}, () => { }]);

const MemberContextProvider = (props) => {
  const [memberId, setMemberId] = useState("");
  const [memberData, setMemberData] = useState({});
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [customerHref, setCustomerHref] = useState(false);
  const [distributorData, setDistributorData] = useState({});
  const [customerHrefData, setCustomerHrefData] = useState({});
  // customerOrdersInitialLength is used for the order count chip in the UI, neccesary because of how we're using offset=11 to determine if there are more orders
  const [customerOrdersInitialLength, setCustomerOrdersInitialLength] = useState(0);
  const [customerOrders, setCustomerOrders] = useState([]);
  const [customerOrdersLength, setCustomerOrdersLength] = useState(0);
  const [customerOrdersOffset, setCustomerOrdersOffset] = useState(0);
  const [customerOrdersOffsetLoading, setCustomerOrdersOffsetLoading] = useState(false);
  const [allOrdersShown, setAllOrdersShown] = useState(false);
  const [customerReferrals, setCustomerReferrals] = useState([]);
  const [customerNotes, setCustomerNotes] = useState([]);
  const [isDistributorLevelLimit, setIsDistributorLevelLimit] = useState(false);

  const [apiChainInProgress, setApiChainInProgress] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const { userInfo } = useAuth();
  const { sendRequest } = useApi();

  const clearErrorMessage = () => {
    setErrorMessage("");
  };

  const processAndSortOrders = (offsetOrders) => {
    const ordersSorted = offsetOrders.sort((a, b) => b.terms.period.localeCompare(a.terms.period));

    // remove duplicate orders due to issues with offset from API
    const uniqueOrders = Object.values(ordersSorted.reduce((acc, order) => {
      acc[order.ResultId] = order;
      return acc;
    }, {}));

    let uniqueOrdersWithIds = uniqueOrders.map((item, idx) => {
      return { uniqueId: idx, ...item };
    })

    return uniqueOrdersWithIds;
  };

  //when unicity id is present in the route, make a call to get the customer href
  useEffect(() => {
    const abortController = new AbortController();

    if (memberId) {
      // when unicityId in URL changes, clear all member data
      setCustomerHref(false);
      setMemberData(false);
      setCustomerHrefData(false);
      setCustomerNotes(false);
      setCustomerOrders([]);
      setAllOrdersShown(false);

      setApiChainInProgress(true);

      // Check if we have prefetched data in location state
      const prefetchedData = window.history.state?.usr?.customerData;
      if (prefetchedData) {
        setMemberData(prefetchedData);
        setCustomerHrefData(prefetchedData);
        setApiChainInProgress(false);
        return;
      }

      sendRequest({
        method: "get",
        endpoint: `customers?unicity=${memberId}&expand=customer`,
      })
        .then((res) => {
          const member = res.data.items[0]
          const selectedPartnerMemberContext = JSON.parse(localStorage.getItem("selectedMemberContext"));
          setMemberData(member);

          const promise1 = sendRequest({
            method: "get",
            addPrefix: false,
            endpoint: `${res.data.items[0].href}?expand=profilePicture`,
            abortController,
          }).catch((error) => {
            setErrorMessage(error.message);
          });

          const promise2 = sendRequest({
            method: "get",
            addPrefix: false,
            endpoint: `${res.data.items[0].href
              }/orders?expand=order${member?.type !== 'Associate' || selectedPartnerMemberContext?.type !== 'partner' ? '&limit=11' : ''}&offset=${customerOrdersOffset}`,
            abortController,
          }).catch((error) => {
            setErrorMessage(error.message);
          });

          const promise3 = sendRequest({
            method: "get",
            addPrefix: false,
            endpoint: `${process.env.REACT_APP_OFFICE_API}/${userInfo.unicityId}/members?sponsorUnicityId=${memberId}`,
            abortController,
          }).catch((err) => console.log(err));

          const promise4 = sendRequest({
            method: "get",
            addPrefix: false,
            endpoint: `${process.env.REACT_APP_OFFICE_API}/${userInfo.unicityId}/notes?customerId=${memberId}`,
            abortController,
          }).catch((err) => console.log(err));

          const promise5 = sendRequest({
            method: "get",
            addPrefix: false,
            endpoint: `${process.env.REACT_APP_OFFICE_API}/${userInfo.unicityId}/distributorLevelLimit?partnerId=${memberId}`,
            abortController,
          }).catch((err) => console.log(err));

          const promise6 = sendRequest({
            method: "get",
            addPrefix: false,
            endpoint: `${process.env.REACT_APP_OFFICE_API}/${userInfo.unicityId}/members?searchTerm=${memberId}&limit=1&sortField=relevance&sortDirection=desc`,
            abortController,
          }).catch((err) => console.log(err));

          Promise.all([promise1, promise2, promise3, promise4, promise5, promise6]).then((values) => {
            try {
              setDistributorData(values[0].data);
              setCustomerHrefData(
                values[0] !== undefined ? values[0].data : false
              );
              const matchingMemberPartner = values[5].data?.items.find((item) => item.unicityId === memberId);
              const count = matchingMemberPartner?.orders;
              // if there are less than 11 orders, set allOrdersShown to true
              const items = values[1] !== undefined ? values[1].data.items : [];
              const filteredCancelledOrders = items.filter((order) => order.fulfillmentStatus !== "Cancelled");
              const selectedMemberContext = JSON.parse(localStorage.getItem("selectedMemberContext"));

              const ordersCount = (count ?? selectedMemberContext?.orders) || 0;
              if ((ordersCount < 11 && filteredCancelledOrders.length >= 11) || ordersCount === filteredCancelledOrders.length) {
                setAllOrdersShown(true);
              }

              setCustomerOrdersInitialLength(filteredCancelledOrders.length);
              // process and sort orders, pop off last order if there are 11
              const ordersProcessed = processAndSortOrders(filteredCancelledOrders);
              if (ordersProcessed.length === 11) ordersProcessed.pop();
              setCustomerOrdersLength(ordersCount);
              setCustomerOrders(ordersProcessed);
              setCustomerReferrals(
                values[2] !== undefined ? values[2].data.items : []
              );
              setCustomerNotes(values[3] !== undefined ? values[3].data.items : [])

              setIsDistributorLevelLimit(values[4]?.data?.isDistributorLevelLimit)
              setApiChainInProgress(false);
            } catch {
              resetContext();
            }
          });
        })
        .catch((error) => {
          setErrorMessage(error.message);
        });
    }
    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberId]);

  // when customerOrdersOffset changes, make a call to get the next 10 orders
  useEffect(() => {
    const abortController = new AbortController();
    if (customerOrdersOffset) {
      setCustomerOrdersOffsetLoading(true);
      sendRequest({
        method: "get",
        endpoint: `customers?unicity=${memberId}&expand=customer`,
      }).then((res) => {
        sendRequest({
          method: "get",
          addPrefix: false,
          endpoint: `${res.data.items[0].href
            }/orders?expand=order&limit=10&offset=${customerOrdersOffset}`,
          abortController,
        }).then((res) => {
          const offsetOrders = res?.data?.items.filter((order) => order.fulfillmentStatus !== "Cancelled");

          const ordersAppended = customerOrders.concat(offsetOrders);
          const processedOrders = processAndSortOrders(ordersAppended);
          if (processedOrders.length === customerOrdersLength) setAllOrdersShown(true);
          setCustomerOrders(processedOrders);
        })
          .catch((error) => {
            setErrorMessage(error.message);
          })
          .finally(() => {
            setCustomerOrdersOffsetLoading(false);
          });
      });
    }
    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerOrdersOffset])

  // when the drawer closes, clear out error message
  useEffect(() => {
    if (!isDrawerOpen) {
      setErrorMessage("");
    }
  }, [isDrawerOpen]);

  const resetContext = () => {
    setMemberId("");
    setMemberData({});
    setCustomerHrefData({});
    setCustomerOrders([]);
    setCustomerOrdersOffset(0);
    setCustomerOrdersLength(0);
    setCustomerReferrals([]);
    setCustomerNotes([]);
    setApiChainInProgress(true);
    setAllOrdersShown(false);
  };

  return (
    <MemberContext.Provider
      value={{
        memberData,
        setMemberData,
        customerHref,
        isDrawerOpen,
        setIsDrawerOpen,
        customerHrefData,
        setCustomerHrefData,
        apiChainInProgress,
        customerOrders,
        errorMessage,
        clearErrorMessage,
        customerReferrals,
        customerNotes,
        memberId,
        setMemberId,
        distributorData,
        resetContext,
        customerOrdersOffset,
        setCustomerOrdersOffset,
        allOrdersShown,
        setAllOrdersShown,
        customerOrdersOffsetLoading,
        customerOrdersInitialLength,
        isDistributorLevelLimit,
        setCustomerOrders,
        setCustomerOrdersLength,
        customerOrdersLength
      }}
    >
      {props.children}
    </MemberContext.Provider >
  );
};

export { MemberContext, MemberContextProvider, };
