import React, { createContext, useState, useMemo, useCallback } from 'react';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import createDefaultTheme from '../themes/createDefaultTheme';
import createDarkTheme from '../themes/createDarkTheme';
import { useMixPanel } from './useMixPanel';

export const ThemeContext = createContext({
  mode: 'light',
  modeSystem: false,
  toggleTheme: () => { },
  setThemeMode: (mode) => { },
  setThemeModeSystem: (modeSystem) => { },
});

export const ThemeProvider = ({ children }) => {
  const { logEvent } = useMixPanel();

  const [mode, setMode] = useState(() => {    
    const savedMode = localStorage.getItem('theme-mode');
    return savedMode || 'light';
  });

  const [modeSystem, setModeSystem] = useState(() => {    
    const savedModeSystem = localStorage.getItem('theme-mode-system');
    return savedModeSystem === 'true' || false;
  });

  const setThemeMode = useCallback((mode) => {
    setMode(mode);
    logEvent(
      'theme_monitor_location',
      {
        version: process.env.REACT_APP_VERSION,
        location: 'set_theme_mode',
        mode: mode,
        system: modeSystem
      }
    );
  }, [ logEvent, modeSystem ]);

  const setThemeModeSystem = useCallback((modeSystem) => {
    setModeSystem(modeSystem);
    logEvent(
      'theme_monitor_location',
      {
        version: process.env.REACT_APP_VERSION,
        location: 'set_theme_mode_system',
        mode: mode === 'light' ? 'dark' : 'light',
        system: modeSystem
      }
    );
  }, [ logEvent, mode ]);

  const toggleTheme = useCallback(() => {
    setMode(mode === 'light' ? 'dark' : 'light');
    setModeSystem(false);    
    logEvent(
      'theme_monitor_location',
      {
        version: process.env.REACT_APP_VERSION,
        location: 'toggle_theme',
        mode: mode === 'light' ? 'dark' : 'light',
        system: modeSystem
      }
    );
  }, [ mode, logEvent, modeSystem ]);

  const theme = useMemo(() => {
    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    let effectiveMode = mode;
    if (modeSystem) {
      effectiveMode = prefersDarkMode ? 'dark' : 'light';
      if (effectiveMode !== mode) {
        setMode(effectiveMode);
      }
    }

    document.documentElement.setAttribute('data-theme', effectiveMode);
    localStorage.setItem('theme-mode', effectiveMode);
    localStorage.setItem('theme-mode-system', modeSystem);    
    
    logEvent(
      'theme_monitor',
      {
        version: process.env.REACT_APP_VERSION,
        location: 'theme_monitor',
        mode: mode,
        system: modeSystem
      }
    );

    return effectiveMode === 'light' ? createDefaultTheme() : createDarkTheme();
  }, [mode, modeSystem, logEvent]);

  const themeContextValue = useMemo(() => ({
    mode,
    modeSystem,
    toggleTheme,
    setThemeMode,
    setThemeModeSystem
  }), [mode, modeSystem, toggleTheme,setThemeMode, setThemeModeSystem]);

  return (
    <ThemeContext.Provider value={themeContextValue}>
      <MUIThemeProvider theme={theme}>
        {children}
      </MUIThemeProvider>
    </ThemeContext.Provider>
  );
}; 