import React, { useState, useEffect } from "react";

import { useAuth } from "../hooks/useAuth";
import useApiWrapper from "../services/api/apiWrapper";

const { DateTime } = require("luxon");

const ActionHubContext = React.createContext([{}, () => { }]);

const ActionHubContextProvider = (props) => {
  //error
  const [error, setError] = useState(false);

  // new feel great
  const [feelGreatCount, setFeelGreatCount] = useState(null);
  const [feelGreatMembers, setFeelGreatMembers] = useState([]);
  const [feelGreatSortModel, setFeelGreatSortModel] = useState([
    { field: "firstFeelGreatOrderDate", sort: "desc" },
  ]);

  //reminders
  const [reminderCount, setReminderCount] = useState(null);
  const [reminders, setReminders] = useState([]);
  const [remindersSortModel, setRemindersSortModel] = useState([
    { field: "lastOrderDate", sort: "desc" },
  ]);

  //partials
  const [partialsCount, setPartialsCount] = useState(null);
  const [partials, setPartials] = useState([]);
  const [partialsSortModel, setPartialsSortModel] = useState([
    { field: "joinDate", sort: "desc" },
  ]);

  // pagniation
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [loading, setLoading] = useState(true);

  // auth and API
  const api = useApiWrapper();
  const { userInfo } = useAuth();

  // Add new loading states
  const [loadingStates, setLoadingStates] = useState({
    feelGreat: true,
    reminders: true,
    partials: true
  });

  const resetContext = () => {
    setFeelGreatCount(null);
    setFeelGreatMembers([]);
    setFeelGreatSortModel([
      { field: "firstFeelGreatOrderDate", sort: "desc" },
    ]);

    setReminderCount(null);
    setReminders([]);
    setRemindersSortModel([
      { field: "lastOrderDate", sort: "desc" },
    ]);

    setPartialsCount(null);
    setPartials([]);
    setPartialsSortModel([
      { field: "joinDate", sort: "desc" },
    ]);

    setPage(0);
    setPageSize(10);

    setLoading(true);

    setLoadingStates({
      feelGreat: false,
      reminders: false,
      partials: false
    });
  }

  useEffect(() => {
    if (!userInfo.loggedIn) {
      resetContext();
    }
  }, [userInfo])


  useEffect(() => {
    if (!userInfo.loggedIn) return;

    const abortController = new AbortController();
    setLoading(true);
    // Set all loading states to true
    setLoadingStates({
      feelGreat: true,
      reminders: true,
      partials: true
    });

    const offset = page * pageSize;

    const awsUrl = `${process.env.REACT_APP_OFFICE_API}/${userInfo.unicityId}/members`;

    let today = DateTime.now();
    let twoWeeksAgo = today.minus({ days: 14 }).toISODate();
    let thirtyFiveDaysAgo = today.minus({ days: 35 }).toISODate();

    const promise1 = api.get(
      `${awsUrl}?&offset=${offset}&limit=${pageSize}&sortField=${feelGreatSortModel[0].field}&firstFeelGreatOrderStartDate=${twoWeeksAgo}&sortDirection=${feelGreatSortModel[0].sort}`,
      {
        addPrefix: false,
        abortController,
      }
    );

    const promise2 = api.get(
      `${awsUrl}?&offset=${offset}&limit=${pageSize}&sortField=${remindersSortModel[0].field}&sortDirection=${remindersSortModel[0].sort}&purchasePeriodEndDate=${thirtyFiveDaysAgo}`,
      {
        addPrefix: false,
        abortController,
      }
    );

    const promise3 = api.get(
      `${awsUrl}?offset=${offset}&limit=${pageSize}&sortField=${partialsSortModel[0].field}&sortDirection=${partialsSortModel[0].sort}&joinedStartDate=${twoWeeksAgo}&joinedEndDate=${today.toISODate()}&filterHasOrders=0`,
      {
        addPrefix: false,
        abortController,
      }
    );

    setLoadingStates(prev => ({ ...prev, feelGreat: true, reminders: true, partials: true }));

    Promise.all([promise1, promise2, promise3])
      .then((values) => {
        const [res1, res2, res3] = values;

        // Update states and their corresponding loading states
        setFeelGreatMembers(
          res1?.data?.items.map((item, idx) => ({ id: idx, ...item }))
        );
        setFeelGreatCount(res1?.data?.hits);
        if (res1) setLoadingStates(prev => ({ ...prev, feelGreat: false }));

        setReminders(
          res2?.data?.items.map((item, idx) => ({ id: idx, ...item }))
        );
        setReminderCount(res2?.data?.hits);
        if (res2) setLoadingStates(prev => ({ ...prev, reminders: false }));

        setPartials(
          res3?.data?.items.map((item, idx) => ({ id: idx, ...item }))
        );
        setPartialsCount(res3?.data?.hits);
        if (res3) setLoadingStates(prev => ({ ...prev, partials: false }));
        setLoading(false);
      })
      .catch((err) => {
        console.log('AH Context Error', err);

        setLoading(false);

        setPartialsCount(0);
        setFeelGreatCount(0);
        setReminderCount(0);

        setError(true);

        // Reset all loading states on error
        setLoadingStates({
          feelGreat: true,
          reminders: true,
          partials: true
        });
      });

    return () => abortController.abort();
    // eslint-disable-next-line
  }, [
    page,
    pageSize,
    remindersSortModel,
    feelGreatSortModel,
    partialsSortModel,
    userInfo,
  ]);

  // reset page to 0 when sortModel is changed
  useEffect(() => {
    setPage(0);
  }, [remindersSortModel, feelGreatSortModel, partialsSortModel]);

  return (
    <ActionHubContext.Provider
      value={{
        feelGreatCount,
        feelGreatMembers,
        reminderCount,
        reminders,
        page,
        setPage,
        pageSize,
        setPageSize,
        loading,
        remindersSortModel,
        setRemindersSortModel,
        feelGreatSortModel,
        setFeelGreatSortModel,
        partials,
        setPartials,
        partialsCount,
        setPartialsCount,
        partialsSortModel,
        setPartialsSortModel,
        error,
        resetContext,
        loadingStates
      }}
    >
      {props.children}
    </ActionHubContext.Provider>
  );
};

export { ActionHubContext, ActionHubContextProvider };
